<div class="directorio-container">
  <h1 class="txt-section">Directorio</h1>
  <div class="directorio_container">
    <div class="form">
        <table>
            <thead>
              <tr>
                <th style="border-top-left-radius: 8px;">SUCURSAL</th>
                <th>TELÉFONO</th>
                <th>DIRECCIÓN OCURRE</th>
                <th style="border-top-right-radius: 8px;">HORARIO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-title='SUCURSAL'><strong> TIJUANA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(664) 647 5194</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Blvd. Insurgentes #16779 Tercera etapa Rio Tijuana CP: 22110</td>
                <td data-title='HORARIO'>Lunes a Sábado de 8:00 a 20:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> MEXICALI CENTRO CIVICO</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(686) 562 1237</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Francisco Sarabia #15 esquina Calera, Centro Civico c.p 21000</td>
                <td data-title='HORARIO'>Lunes a Sábado de 8:00 a 18:00 hrs - Domingo Cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> SAN LUIS RC</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(653) 534 5832</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Av. Obregon y calle 6ta. #508  Col Comercial  C.P 83449  </td>
                <td data-title='HORARIO'>Lunes a Sábado de 8:00 a 19:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> SONOYTA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(651) 512 0868</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Blvd. Benemerito de las Americas #104 local F Col. Centro CP: 83570</td>
                <td data-title='HORARIO'>Lunes a Domingo 8:00 a 21:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> SANTA ANA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(641) 324 0124</a></td>
                <td data-title='DIRECCIÓN OCURRE'>General Anaya y Cuauhtemoc s/n Col. Del rio CP: 84600</td>
                <td data-title='HORARIO'>Lunes a Viernes 9:00 a 17:00 hrs - Sábado 9:00 a 13:00 hrs - Domingo cerrado.</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> CABORCA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(637) 372 3694</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Carretera Internacional entre 8 y 9 Col. Centro CP: 83600</td>
                <td data-title='HORARIO'>Lunes a Sabado 9:00 a 17:00 - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> NOGALES</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(631) 313 3862</a> Taquilla</td>
                <td data-title='DIRECCIÓN OCURRE'>Av.Obregon #2030 Col. Kalitea CP: 84065</td>
                <td data-title='HORARIO'>Lunes a Sábado 7:00 a 21:00 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> AGUA PRIETA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(633) 338 3773</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Av. 6 y calle 25 Col. Ejidal CP: 84200</td>
                <td data-title='HORARIO'>Lunes a Viernes 7:00 a 21:00 hrs - Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> CANANEA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(645) 332 2500</a> Ext 2</td>
                <td data-title='DIRECCIÓN OCURRE'>Camino Real y Blvd Lázaro Gutierrez de Lara. s/n Col. Linda Vista CP: 84620</td>
                <td data-title='HORARIO'>Lunes a Viernes 6:00 a 20:00 hrs - Sábado 6:00 a 19:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> MAGDALENA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(632) 322 0613</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Niños Heroes y Allende #103 Col. Centro CP: 84160</td>
                <td data-title='HORARIO'>Lunes a Sabado 7:00 a 20:30 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <!-- <tr>
                <td data-title='SUCURSAL'><strong> IMURIS</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(632) 688 1698</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Luis Donaldo Colosio # 175 Col. Centro, CP 84120</td>
                <td data-title='HORARIO'>Lunes a Domingo 6:30 a 21:00 hrs</td>
              </tr> -->
              <tr>
                <td data-title='SUCURSAL'><strong> HERMOSILLO MONTEVERDE</strong></td>
               <td data-title='TELÉFONO'> <a href="tel:">(662) 289 6970</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Monteverde esquina Camelia s/n Col. Luis Encinas CP: 85138</td>
                <td data-title='HORARIO'>Lunes a Viernes de 9:00 a 18:00 hrs  - Sabados 9:00 a 13:00  - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> HERMOSILLO GIRASOL</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(662) 216 5600</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Blvd. Solidaridad #516 Local 17 Col. El Sahuaro CP: 85138</td>
                <td data-title='HORARIO'>Lunes a Viernes 9:00 a 19:00 hrs - Sábado 9:00 a 14:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> HERMOSILLO - MÓDULO CENTRAL</strong></td>
                <td data-title='TELÉFONO'><a href="tel:"></a></td>
                <td data-title='DIRECCIÓN OCURRE'>Boulevard Luis Encinas Jhonson #337, colonia universidad, C.P. 83040, Hermosillo, Sonora</td>
                <td data-title='HORARIO'>Lunes a Sábado de 9:00 a 17:00 - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> HERMOSILLO</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(662) 289 6970</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Blvd. Luis Encinas #393 esquina Ferrocarril Col. Coloso Bajo  C.P. 83079</td>
                <td data-title='HORARIO'>Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> GUAYMAS</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(622) 222 5453</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Calzada Agustin Garcia Lopez #927 Col. Delicias CP: 85400</td>
                <td data-title='HORARIO'>Lunes a Sabado 7:00 a 20:00 hrs -  Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> EMPALME</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(622) 223 2770</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Carretera Internacional km 2 s/n Colonia Moderna CP: 85330</td>
                <td data-title='HORARIO'>Lunes a Sábado 9:00 a 17:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> CD. OBREGÓN</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(644) 416 2668</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Calle California # 1019 entre Guadalupe Victoria y Churubusco  85150</td>
                <td data-title='HORARIO'>Lunes a Sabado 7:00 a 20:0 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> HUATABAMPO</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(644) 206 0299 </a></td>
                <td data-title='DIRECCIÓN OCURRE'>16 de Septiembre 534, Centro Comercial, 85900 Huatabampo, Son.</td>
                <td data-title='HORARIO'>Lunes a Sábado 8:00 a 20:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> NAVOJOA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(642) 421 3410 Ext 103</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Calle Gral. I. Pesqueira #515, Juárez, 85800 Navojoa, Son.</td>
                <td data-title='HORARIO'>Lunes a Sabado 7:00 a 20:00 hrs -Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> LOS MOCHIS</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(668) 817 5551</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Blvd. Antonio Rosales #2465 Nte. Entre Blvd. Zacarias y Blvd. Poseidon  c.p 81210</td>
                <td data-title='HORARIO'>Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> GUASAVE</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(687) 721 1236</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Blvd. Central y Gabriel Medina Martinez Col. Centro CP: 81000</td>
                <td data-title='HORARIO'>Lunes a Sábado 7:00 a 20:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> GUAMUCHIL</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(673) 734 1853</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Nicolás BRAVO #9 entre Salvador Alvarado y Francisco V. Col. Juárez</td>
                <td data-title='HORARIO'>Lunes a Sábado 7:00 a 20:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> C.OP CULIACÁN</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(667) 760 0504</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Industrias del Valle #2857 Parque Industrial Canacintra II CP: 85550</td>
                <td data-title='HORARIO'>Lunes a Viernes 7:00 a 19:00 hrs - Sabados 7:00 a 15:00 Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> CULIACÁN CENTRAL</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(667) 714 1558</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Alfonso G. Calderón Col Nuevo Milenium local 7  Central de Autobuses</td>
                <td data-title='HORARIO'>Lunes a Sábado 7:00 a 20:00 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> MAZATLÁN</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(669) 968 0351</a> EXT 103 y 104</td>
                <td data-title='DIRECCIÓN OCURRE'>Carretera Internacional al norte #3014 Col. Isla Residencial CP 82157</td>
                <td data-title='HORARIO'>Lunes a Viernes 7:00 a 20:00 hrs - Sábado 7:00 a 18:00 hrs - Domingo 8:00 a 15:00 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> C.OP GUADALAJARA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(333) 860 2282</a> <br> <a href="tel:">(33)3657 7360</a> <br> <a href="tel:">(33)3838 4334</a> </td>
                <td data-title='DIRECCIÓN OCURRE'> Esmeril #1985 entre Lazarro Cardenas y Refineria Col. Alamo Industrial </td>
                <td data-title='HORARIO'>Lunes a Viernes 8:00 a 20:00 hrs - Sábado 8:00 a 15:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong>MODULO PATRIA, ZAPOPAN JALISCO</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">01 (333) 630 8607</a></td>
                <td data-title='DIRECCIÓN OCURRE'> Av. Patria #2690 int 1, entre Volcan Ajusco y Volcan Popocatépetl Col. Colli Urbano C.P. 45070 Zapopan Jalisco </td>
                <td data-title='HORARIO'>Lunes a Viernes 10:00 a 18:00 hrs - Sábado 9:00 a 13:00 hrs - Domingo cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> TEPIC</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(311) 129 2010</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Ave. Tecnológico #2700 entre Isla de Cuba y Diamante, Col. Valle de Matatipac, C.P.63180</td>
                <td data-title='HORARIO'>Lunes a Viernes 7:00 a 21:00 hrs - Sábado 7:00 a 15:00 hrs Domingo Cerrado</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> ACAPONETA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(311) 200 1032</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Crucero Acaponeta #15</td>
                <td data-title='HORARIO'>Lunes a Domingo 6:30 a 20:30 hrs</td>
              </tr>
              <tr>
                <td data-title='SUCURSAL'><strong> EL ROSARIO</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(694) 952 0138</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Av Rotarismo s/n salida a agua verde, Marcelo Loya Rosario</td>
                <td data-title='HORARIO'>Lunes a Domingo 6:30 a 20:30 hrs</td>
              </tr>
              <!-- <tr>
                <td data-title='SUCURSAL'><strong> ESCUINAPA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(695) 953 0288</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Miguel Hidalgo y Costilla #44 Col. Centro</td>
                <td data-title='HORARIO'>Lunes a Domingo 6:30 a 20:30 hrs</td>
              </tr> -->
              <!-- <tr>
                <td data-title='SUCURSAL' style="border-bottom-left-radius: 8px;"><strong> TECUALA</strong></td>
                <td data-title='TELÉFONO'><a href="tel:">(389) 253 2197</a></td>
                <td data-title='DIRECCIÓN OCURRE'>Av. Zaragoza #71</td>
                <td data-title='HORARIO' style="border-bottom-right-radius: 8px;">Lunes a Domingo 6:30 a 20:30 hrs</td>
              </tr> -->
            </tbody>
          </table>
    </div>
  </div>
</div>
