import { Component, OnInit, AfterViewInit } from '@angular/core';
import { recolecciondom } from "../../servicios/recoleccion-dom.service";
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { Router } from "@angular/router";
import { ActivatedRoute, Params } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-guias-web',
  templateUrl: './guias-web.component.html',
  styleUrls: ['./guias-web.component.css']
})
export class GuiasWebComponent implements OnInit, AfterViewInit {
  datapaqsguia: any;
  datapaqdetalle:any;

  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number=-1;
  isLoguedin: boolean;
  costo:string;

  to:any = {
    comp:'',
    rf:''
  }



  constructor(private recolecciondom: recolecciondom, private router: Router,) { }

  ngOnInit(): void {
    this.obtenerPaquetes();

  }

  ngAfterViewInit() {
    this.initCarousel();

  }


  // arreglo con los datos de los paquetes de los tipos de guia
  guiasPaquetes = [
    {
      id: 0,
      numGuias: 0,
      nombre: "",
      costo: "",
      descripcion1: "",
      descripcion2: ""
    }

    // {
    //   id: 1,
    //   numGuias: 20,
    //   nombre: "Guias web",
    //   costo: "250.00",
    //   descripcion1: "Comprar guías web",
    //   descripcion2: "Consulta términos y condiciones"
    // }

    // {
    //   id: 2,
    //   numGuias: 50,
    //   nombre: "Guias digitales",
    //   costo: 500,
    //   descripcion1: "Comprar guias digitales",
    //   descripcion2: "Consulte restricciones"
    // },
    // {
    //   id: 3,
    //   numGuias: 100,
    //   nombre: "Guias digitales",
    //   costo: 750,
    //   descripcion1: "Comprar guias digitales",
    //   descripcion2: "Consulte restricciones"
    // },
  ];

  guiasDescripcion =
    {
      id: 0,
      numGuias: 0,
      costo: "",
      detalle: "",
      tipo: "",
      beneficios: [""]
    };

  obtenerPaquetes(){
    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      }
      else{
        this.idcliente=-1;
      }
      this.recolecciondom.getPaquetesGuias(this.idcliente).then((data) => {
        this.datapaqsguia = data;
        this.guiasPaquetes = this.datapaqsguia;
        this.obtenerDetalle(this.guiasPaquetes[0].id)
      });
    }

    obtenerDetalle(idpaquete){
      if(sessionStorage.getItem("token")){
        this.token = sessionStorage.getItem("token");
        this.tokendatos = jwt_decode(this.token);
        this.userGw = this.tokendatos;
        this.idcliente = this.userGw.idUser;
        }
        else{
          this.idcliente=-1;
        }
      this.recolecciondom.getDetallePaquetesGuias(idpaquete,this.idcliente).then((data) => {
        this.datapaqdetalle = data;
        this.guiasDescripcion = this.datapaqdetalle;
      });
    }
    comprarGuias(){

      // localStorage.setItem("totalGuia", this.guiasDescripcion.costo);
      if(sessionStorage.getItem("token")){
        this.token = sessionStorage.getItem("token");
        this.tokendatos = jwt_decode(this.token);
        this.isLoguedin = true;
        this.userGw = this.tokendatos;
        this.idcliente = this.userGw.idUser;
          var cadpago="";
          cadpago =  btoa( JSON.stringify (this.guiasDescripcion));
          this.router.navigate(["/detalle-transaccion"],{queryParams:{rf:cadpago}});
        }
       else {
        this.isLoguedin = false;

        this.router.navigate(["/herramientasDigitales/login"],{queryParams:{to:'guias-web'}});
      }
    }

    initCarousel(){
      // carousell, permite que no halla continuidad de los items dentro
      $('#carousel-example').on('slide.bs.carousel', function (e) {
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 3;
        var totalItems = $('.carousel-item').length;

        if (idx >= totalItems-(itemsPerSlide-1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i=0; i<it; i++) {
                // append slides to end
                if (e.direction=="left") {
                    $('.carousel-item').eq(i).appendTo('.carousel-inner');
                }
                else {
                    $('.carousel-item').eq(0).appendTo('.carousel-inner');
                }
            }
        }
      });
  }

  formatearCosto(costo: string): string {
    const costoFormateado = parseFloat(costo).toLocaleString('en-US', { minimumFractionDigits: 2 });
    return `$${costoFormateado}`;
    // return `$${costoFormateado} MXN`;
  }

  }
