import { Directive, ElementRef, NgZone, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appVisibility]'
})
export class VisibilityDirective {
  private observer: IntersectionObserver | null = null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.renderer.addClass(this.el.nativeElement, 'visible');
            if (this.observer) {
              this.observer.unobserve(this.el.nativeElement);
            }
          }
        });
      });

      if (this.observer) {
        this.observer.observe(this.el.nativeElement);
      }
    });
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}