<div class="container_herramienta">
    <img src="assets/img/beneficios/landing_pkganes.png " class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="title-section">Servicio Referenciados PAKGANES</h1>
            <div class="descripcion_cont">
            <p>
              <b>Invita y obtén beneficios</b><br>
              Con PaKGanes, puedes obtener recompensas al invitar a otros a disfrutar de la calidad y confianza que ya conoces en TufesaPack®.
              ¡Recomendar nunca había sido tan fácil ni tan beneficioso!
            </p>
            <p>
                <b>¿Cómo funciona?</b><br>
                Recomienda nuestros servicios a tus conocidos que hagan envíos regularmente.<br>
                Por cada nuevo contrato que se cierre gracias a ti, obtendrás beneficios exclusivos.
                
            </p>
            <p>
                <b>Beneficios</b><br>
                Disfruta de tus recompensas en automático mientras tus referidos disfrutan de nuestros servicios.
                <br>
                <li><strong>5 Envíos Gratis</strong> (máximo 20 kilogramos por paquete, válidos durante los primeros 30 días).</li>
                <li><strong>10% de Reembolso</strong> en la primer compra del primer cliente cautivo.</li>
            </p>
            <p>
                <b>Disponibilidad</b><br>
                PaKGanes está disponible en todas las sucursales, pero la capacidad de envío puede variar según la ubicación, 
                algunas de las siguientes sucursales con capacidad son:
                <br><br>
                <li>Guadalajara</li>
                <li>Hermosillo</li>
                <li>Ciudad Obregón</li>
                <li>Los Mochis</li>
                <li>Tijuana</li>
            </p>

            <p>
                <b>Restricciones</b><br>
  
                <li>Registro de formato institucional de recomendación (con firma de conformidad).</li>
                <li>Beneficios aplican en clientes cautivos con servicio prepago o crédito (sin morosidad).</li>
                <li>los clientes calificados para obtener los beneficios de esta dinámica deberán contar con un mínimo de antigüedad
                     de 3 meses con contrato ya activo con Tufesapack, si el solicitante no tiene un contrato con Tufesapack igual o
                      mayor a 3 meses, no podrá solicitar los beneficios de la dinámica Pakganes.</li>
                <li>Beneficios se obtienen en contrato especial con TufesaPack®, plazo forzoso de 3 meses.</li>
                <li>Envíos gratuitos obtenidos en beneficios quedan exentos a obligaciones TufesaPack® expresadas en contrato de adhesión.</li>
                <li>Este tipo de contrato no participa en comisiones individuales a ejecutivos de venta.</li>
                <li>La primera recarga y/o facturación del recomendado deberá ser liquidad dentro de plazo establecido en políticas de TufesaPack®.</li>
                
            </p>

            <p>
                <b>¿Por qué elegir TufesaPack®?</b><br>
                Con TufesaPack®, obtienes más que envíos rápidos y seguros; garantizamos soluciones personalizadas, 
                incentivos únicos, una experiencia confiable que mejora tu logística y reduce tus costos combinando innovación
                 y servicio para ofrecerte soluciones que realmente se ajusten a tus necesidades.
                 <br>   <br>
                 Recomienda nuestros servicios para que tus conocidos den el primer paso hacia un servicio de paquetería más eficiente
                  y lleno de beneficios, donde hacemos más fácil y rentable sus envíos.
            </p>

            <p class="footertxt">Vigencia de la promoción: Disponible hasta el 31 de diciembre de 2025.</p>
  
        </div>
    </div>
  </div>
  
  
