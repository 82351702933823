<div class="bi-container">
    <div class="espacio"></div>
   <div class="redondo">
     <h1 inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onIntersectionText($event)">Nuestros Servicios</h1>

       <div class="item-container">
        <a routerLink="/mensajeria"  class="linked">
        <div id="1" class="bi-item" inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onIntersection($event)">
            <img id="img_deskop" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/sobre.webp" alt="Icono Tufesa Envios">
            <img id="img_mobil" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/mobil/sobre.webp" alt="Icono Tufesa Envios">
            <h6>Mensajería</h6>
            <p class="txt_prop">
                Envío en sobre de hasta 1 kg.
            </p>
            <a href=""><p class="btn_mensajeria">Conoce más</p></a>
        </div>
        </a>
        <a routerLink="/mensajeria" class="linked">
        <div id="2" class="bi-item" inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onIntersection($event)">
            <img id="img_deskop" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/paqueteria.webp" alt="Icono Tufesa Envios" style="width: 106px;">
            <img id="img_mobil" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/mobil/paqueteria.webp" alt="Icono Tufesa Envios" style="width: 106px;">
            <h6>Paquetería</h6>
            <p class="txt_prop">
                Envío de paquetes de hasta 31 kg en caja.
            </p>
            <a href=""><p class="btn_mensajeria">Conoce más</p></a>
        </div>
        </a>
        <a routerLink="/carga" class="linked">
        <div id="3" class="bi-item" inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onIntersection($event)">
            <img id="img_deskop" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/carga.webp" alt="Icono Tufesa Envios" style="width: 180px;;margin-bottom: 18px;margin-top: 42px;">
            <img id="img_mobil" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/mobil/carga.webp" alt="Icono Tufesa Envios" style="width: 180px;margin-bottom: 18px;margin-top: 42px;">
            <h6>Carga</h6>
            <p class="txt_prop">
                Envío a partir de 32 kgs.
            </p>
            <a href=""><p class="btn_mensajeria">Conoce más</p></a>
        </div>
        </a>
        <a routerLink="/delivery" class="linked">
        <div id="4" class="bi-item" inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onIntersection($event)">
            <img id="img_deskop" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/delivery.webp" alt="Icono Tufesa Envios" style="width:180px;margin-bottom: 4px;margin-top: 23px;">
            <img id="img_mobil" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/servicios/mobil/delivery.webp" alt="Icono Tufesa Envios" style="width:180px;margin-bottom: 4px;margin-top: 23px;">
            <h6>Delivery</h6>
            <p class="txt_prop">
                Te representamos con profesionalismo en cada entrega, ya sea terrestre o aéreo.
            </p>
            <a href=""><p class="btn_mensajeria">Conoce más</p></a>
        </div>
    </a>

    <a routerLink="/guias-web" class="linked">
        <div id="3" class="bi-item" inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onIntersection($event)">
            <img id="img_deskop" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/info-util/icon_tipsSeg.webp" alt="Icono Tufesa Envios" style="width: 112px;margin-bottom: 7px;margin-top: 16px;">
            <img id="img_mobil" width="100%" height="100%" class="lazyload fade" data-src="assets/iconos/info-util/icon_tipsSeg.webp" alt="Icono Tufesa Envios" style="width: 112px;margin-bottom: 7px;margin-top: 16px;">
            <h6>Guías web</h6>
            <p class="txt_prop">
                Ahora mas fácil y rápido, haz más envíos comprando guías prepagadas y ahorra.
            </p>
            <a href=""><p class="btn_mensajeria">Conoce más</p></a>
        </div>
        </a>

     </div>
  </div>
</div>

<!-- <div class="guias_prom">

    <a id="link_inicio_page_guiasweb" routerLink="/tufesapack-horas" class="linked">
        <div id="inicio_page_guiasweb" class="box-item">
            <h6>Tufesapack Horas</h6>
            <p class="txt_prop">
                Tus envíos llegan el <br> mismo día que envías.
            </p>
            <a><p class="btn_mensajeria2">Conoce más</p></a>
        </div>
    </a>
    <a id="link_inicio_page_promociones" routerLink="/promociones" class="linked">
        <div id="inicio_page_promociones" class="box-item">
            <h6>Promociones</h6>
            <p class="txt_prop">
                Hazte Socio Tufesapack y obtén grandes beneficios que tenemos para ti.
            </p>
            <a href=""><p class="btn_mensajeria2">Conoce más</p></a>
        </div>
    </a>
</div> -->
