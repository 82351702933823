import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tufesapack-pkganes',
  templateUrl: './tufesapack-pkganes.component.html',
  styleUrls: ['./tufesapack-pkganes.component.css']
})
export class TufesapackPkganesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
