import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PreguntasFrecuentesComponent } from 'src/app/componentes/preguntas-frecuentes/preguntas-frecuentes.component';
import { IndexComponent } from 'src/app/componentes/index/index.component';
import { PoliticasComponent } from 'src/app/componentes/politicas/politicas.component';
import { TipsSeguridadComponent } from 'src/app/componentes/tips-seguridad/tips-seguridad.component';
import { AvisoPrivacidadComponent } from 'src/app/componentes/aviso-privacidad/aviso-privacidad.component';
import { BuzonComponent } from 'src/app/componentes/buzon/buzon.component';
import { DirectorioComponent } from 'src/app/componentes/directorio/directorio.component';
import { BolsaTrabajoComponent } from 'src/app/componentes/bolsa-trabajo/bolsa-trabajo.component';
import { CondicionesComponent } from 'src/app/componentes/condiciones/condiciones.component';
import { FacturacionComponent } from 'src/app/componentes/facturacion/facturacion.component';
import { PaqueteriaRastreoComponent } from 'src/app/componentes/paqueteria-rastreo/paqueteria-rastreo.component';
import { PaqueteriaRecoleccionComponent } from 'src/app/componentes/paqueteria-recoleccion/paqueteria-recoleccion.component';
import { CoberturaComponent } from 'src/app/componentes/cobertura/cobertura.component';
import { CotizacionComponent } from 'src/app/componentes/cotizacion/cotizacion.component';
import { CotizacionResumenComponent } from 'src/app/componentes/cotizacion-resumen/cotizacion-resumen.component';
import { ServiciosComponent } from 'src/app/componentes/servicios/servicios.component';
import { EnviosExtranjeroComponent } from 'src/app/componentes/envios-extranjero/envios-extranjero.component';
import { ChatComponent } from 'src/app/componentes/chat/chat.component';
import { LoLlevaComponent } from 'src/app/componentes/lo-lleva/lo-lleva.component';
import { EnvioResumenComponent } from 'src/app/componentes/envio-resumen/envio-resumen.component';
import { RastreoComponent } from 'src/app/componentes/rastreo/rastreo.component';
import { RastreoSolicitudComponent } from './componentes/rastreo-solicitud/rastreo-solicitud.component';
import { RastreoConfirmacionComponent } from './componentes/rastreo-confirmacion/rastreo-confirmacion.component';
import { GuiasClientesComponent } from './componentes/guias-clientes/guias-clientes.component';
import { GwUserLoginComponent } from './componentes/gw-user-login/gw-user-login.component';
import { GwMenuPrincipalComponent } from './componentes/gw-menu-principal/gw-menu-principal.component';
import { GwMenuDocumentacionComponent } from './componentes/gw-menu-documentacion/gw-menu-documentacion.component';
import { GwDocRemitenteComponent } from './componentes/gw-doc-remitente/gw-doc-remitente.component';
import { GwDocDestinatarioComponent } from './componentes/gw-doc-destinatario/gw-doc-destinatario.component';
import { GwDocTipoComponent } from './componentes/gw-doc-tipo/gw-doc-tipo.component';
import { GwDocDetalleComponent } from './componentes/gw-doc-detalle/gw-doc-detalle.component';
import {GwDocCompletaComponent} from './componentes/gw-doc-completa/gw-doc-completa.component'
import { GwDocEstructuraComponent } from './componentes/gw-doc-estructura/gw-doc-estructura.component';
import { ListadoRecoleccionComponent } from './componentes/listado-recoleccion/listado-recoleccion.component';
// import { GwSolicitarGuiasComponent } from './componentes/gw-solicitar-guias/gw-solicitar-guias.component';
import { GwSolicitarguiasComponent } from './componentes/gw-solicitarguias/gw-solicitarguias.component';
import { GwHistorialComponent } from './componentes/gw-historial/gw-historial.component';
import { GwFacturasComponent } from './componentes/gw-facturas/gw-facturas.component';
import { GwUsrRegistroComponent } from './componentes/gw-usr-registro/gw-usr-registro.component';
import { GwHistorialDetComponent } from './componentes/gw-historial-det/gw-historial-det.component';
import { GwRestablecerpasswordComponent } from './componentes/gw-restablecerpassword/gw-restablecerpassword.component';
import { GwVerificarcuentaComponent } from './componentes/gw-verificarcuenta/gw-verificarcuenta.component';
import { GwCambiarpasswordComponent} from './componentes/gw-cambiarpassword/gw-cambiarpassword.component';
import { ContratoAdhesionComponent } from './componentes/contrato-adhesion/contrato-adhesion.component';
import { HerramientasMenuComponent } from './componentes/herramientas-menu/herramientas-menu.component';
import { RecoleccionComponent } from './componentes/recoleccion/recoleccion.component';
import { PromocionesComponent } from './componentes/promociones/promociones.component';
import { VerificaComponent } from './componentes/verifica/verifica.component';
import { ContactoComponent } from './componentes/contacto/contacto.component';
import { MensajeriaComponent } from './componentes/mensajeria/mensajeria.component';
import { PaqueteriaComponent } from './componentes/paqueteria/paqueteria.component';
import { CargaComponent } from './componentes/carga/carga.component';
import { DeliveryComponent } from './componentes/delivery/delivery.component';
import { GrupoTufesaComponent } from './componentes/grupo-tufesa/grupo-tufesa.component';
import { NuestrasMarcasComponent } from './componentes/nuestras-marcas/nuestras-marcas.component';
import { GuiasWebComponent } from './componentes/guias-web/guias-web.component';
import { CodigoPromocionComponent } from './componentes/codigo-promocion/codigo-promocion.component';
import { AdministrarCuentaComponent } from './componentes/administrar-cuenta/administrar-cuenta.component';
import { DetalleTransaccionComponent } from './componentes/detalle-transaccion/detalle-transaccion.component';
import { CapturaPagoComponent } from './componentes/captura-pago/captura-pago.component';
import { TupromoComponent } from './componentes/tupromo/tupromo.component';
import { Tupromo2Component } from './componentes/tupromo2/tupromo2.component';
import { ConfirmacionComponent } from './componentes/confirmacion/confirmacion.component';
import { HistorialCompraComponent } from './componentes/historial-compra/historial-compra.component';
import { DocumentosComponent } from './componentes/documentos/documentos.component';
import { MisformatosComponent  } from './componentes/misformatos/misformatos.component';
import { AfiliadosComponent  } from './componentes/afiliados/afiliados.component';
import { CrearMisFormatosComponent } from './componentes/crear-mis-formatos/crear-mis-formatos.component';
import { AniversarioComponent } from './componentes/aniversario/aniversario.component';
import { TufesapackHorasComponent } from './componentes/tufesapack-horas/tufesapack-horas.component';
import { TufesapackPaktcambiesComponent } from './componentes/tufesapack-paktcambies/tufesapack-paktcambies.component';
import { TufesapackPkganesComponent } from './componentes/tufesapack-pkganes/tufesapack-pkganes.component';
import { TufesapackAniversarioComponent } from './componentes/tufesapack-aniversario/tufesapack-aniversario.component';


const routes: Routes = [
  { path: '', component: IndexComponent, pathMatch: 'full' },
  { path: 'preguntasFrecuentes', component: PreguntasFrecuentesComponent },
  { path: 'informacion/politicas', component: PoliticasComponent },
  { path: 'informacion/tipsseguridad', component: TipsSeguridadComponent },
  { path: 'informacion/avisoPrivacidad', component: AvisoPrivacidadComponent },
  { path: 'informacion/directorio', component: DirectorioComponent },
  { path: 'informacion/envios', component: EnviosExtranjeroComponent },
  { path: 'informacion/buzon', component: BuzonComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'bolsatrabajo', component: BolsaTrabajoComponent },
  { path: 'condiciones', component: CondicionesComponent },
  { path: 'contrato/adhesion', component:ContratoAdhesionComponent},
  { path: 'facturacion', component: FacturacionComponent },
  { path: 'paqueteria/rastreo/captura', component: RastreoComponent },
  { path: 'paqueteria/rastreo/solicitud', component: RastreoSolicitudComponent },
  { path: 'paqueteria/rastreo/confirmacion', component: RastreoConfirmacionComponent },
  { path: 'paqueteria/recoleccion', component: PaqueteriaRecoleccionComponent },
  { path: 'sucursales', component: CoberturaComponent },
  { path: 'cotizacion', component: CotizacionComponent },
  { path: 'cotizacion/resumen', component: CotizacionResumenComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'EnviosLocales', component: LoLlevaComponent },
  { path: 'enviosLocales', redirectTo: 'EnviosLocales' },
  { path: 'envioslocales', redirectTo: 'EnviosLocales' },
  { path: 'confirmacion', component: EnvioResumenComponent },
  { path: 'guias/clientes', component: GuiasClientesComponent },
  { path: 'herramientasDigitales/login', component: GwUserLoginComponent },
  { path: 'herramientasDigitales/menu', component: GwMenuPrincipalComponent},
  { path: 'herramientasDigitales/documentacion/menu', component: GwMenuDocumentacionComponent},
  { path: 'herramientasDigitales/Documentacion/remitente', component: GwDocRemitenteComponent},
  { path: 'herramientasDigitales/Documentacion/destinatario', component: GwDocDestinatarioComponent},
  { path: 'herramientasDigitales/Documentacion/tipo2', component: GwDocTipoComponent},
  { path: 'herramientasDigitales/Documentacion/detalle', component: GwDocDetalleComponent},
  { path: 'herramientasDigitales/Documentacion/confirmacion', component: GwDocCompletaComponent},
  { path: 'herramientasDigitales/guias', component: GwSolicitarguiasComponent },
  { path: 'herramientasDigitales/facturas', component: GwFacturasComponent },
  { path: 'herramientasDigitales/historial', component: GwHistorialComponent },
  { path: 'herramientasDigitales/Documentacion', component: GwDocEstructuraComponent },
  { path: 'herramientasDigitales/listado-recoleccion', component: ListadoRecoleccionComponent},
  { path: 'herramientasDigitales/registro', component: GwUsrRegistroComponent },
  { path: 'herramientasDigitales/historial/detalle', component: GwHistorialDetComponent },
  { path: 'herramientasDigitales/restablecer/contrasena', component: GwRestablecerpasswordComponent },
  { path: 'herramientasDigitales/verificar/cuenta', component: GwVerificarcuentaComponent },
  { path: 'herramientasDigitales/contrasena', component: GwCambiarpasswordComponent },
  { path: 'herramientas-menu', component: HerramientasMenuComponent },
  { path: 'recoleccion', component: RecoleccionComponent},
  { path: 'promociones', component: PromocionesComponent},
  { path: 'verifica', component: VerificaComponent},
  { path: 'contacto', component: ContactoComponent},
  { path: 'mensajeria', component: MensajeriaComponent},
  { path: 'paqueteria', component: PaqueteriaComponent},
  { path: 'carga', component: CargaComponent},
  { path: 'delivery', component: DeliveryComponent},
  { path: 'grupo-tufesa', component: GrupoTufesaComponent},
  { path: 'nuestras-marcas', component: NuestrasMarcasComponent},
  { path: 'guias-web', component: GuiasWebComponent },
  { path: 'codigo-promocion', component: CodigoPromocionComponent },
  { path: 'herramientasDigitales/administrar-cuenta', component: AdministrarCuentaComponent },
  { path: 'herramientasDigitales/administrar-cuenta/upload', component: AdministrarCuentaComponent },
  { path: 'detalle-transaccion', component: DetalleTransaccionComponent },
  { path: 'captura-pago', component: CapturaPagoComponent},
  { path: 'tupromo', component: TupromoComponent},
  { path: 'tupromo2', component: Tupromo2Component},
  { path: 'confirmacion2', component: ConfirmacionComponent},
  { path: 'historial-compra', component: HistorialCompraComponent},
  { path: 'documentos/:code', component: DocumentosComponent},
  { path: 'mis-documentos', component: MisformatosComponent },
  { path: 'afiliados', component: AfiliadosComponent },
  { path: 'crear-mis-documentos', component: CrearMisFormatosComponent},
  { path: 'primeraniversario12024', component: AniversarioComponent},
  { path: 'tufesapack-horas', component: TufesapackHorasComponent},
  { path: 'PaKTCambies', component: TufesapackPaktcambiesComponent},
  { path: 'PaKGanes', component: TufesapackPkganesComponent},
  { path: 'Aniversario2', component: TufesapackAniversarioComponent},
  


  // cualquier otra url redirigir a home
  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
