import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tufesapack-beneficios',
  templateUrl: './tufesapack-beneficios.component.html',
  styleUrls: ['./tufesapack-beneficios.component.css']
})
export class TufesapackBeneficiosComponent implements OnInit {

  paktcambiesbeneficio:boolean = true;
  horasbeneficio:boolean = false;
  pakganesbeneficio: boolean= false;

  activeToggle: string = 'paktcambies';

  cardpaktcambies: boolean = false;
  cardhoras: boolean = true;
  cardpakganes: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  showBeneficio(param:string){
    this.activeToggle = param;
    if(param == "paktcambies"){
       this.paktcambiesbeneficio = true;
       this.horasbeneficio = false;
       this.pakganesbeneficio = false;

       this.cardpaktcambies = false;
       this.cardhoras = true;
       this.cardpakganes = true;
    }

    if(param == "horas"){
       this.paktcambiesbeneficio = false;
       this.horasbeneficio = true;
       this.pakganesbeneficio = false;

       this.cardpaktcambies = true;
       this.cardhoras = false;
       this.cardpakganes = true;
    }
    if(param == "pakganes"){
      this.paktcambiesbeneficio = false;
      this.horasbeneficio = false;
      this.pakganesbeneficio = true;

      this.cardpaktcambies = true;
      this.cardhoras = true;
      this.cardpakganes = false;
   }
  }

}
