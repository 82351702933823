import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-tufesapack-aniversario',
  templateUrl: './tufesapack-aniversario.component.html',
  styleUrls: ['./tufesapack-aniversario.component.css']
})
export class TufesapackAniversarioComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }
}
