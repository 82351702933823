<div class="content-full-section">

  <!--   -----------   Seccion beneficios paktcambies    -----------   -->
  <div class="content-beneficio" *ngIf="paktcambiesbeneficio">
    <div class="content-text">
      <p appVisibility class="fadeInLeft">¡ES HORA DE UN CAMBIO!</p>
      <p appVisibility class="bounceInLeft">Optimiza tus envíos y obtén beneficios exclusivos.</p>
      <button id="btnpaktdesktop" routerLink="/PaKTCambies">¡Cámbiate aquí! <span class="material-symbols-outlined">
          chevron_right </span></button>
    </div>
    <!-- <img id="logopktbannerdesktop" src="assets/img/beneficios/PAKTCAMBIES/tfpHoras.gif" appVisibility class="image-logo-pktcambies swing"> -->
    <img id="logopktbannerdesktop" src="assets/img/beneficios/PAKTCAMBIES/PAKTCAMBIES.webp"
      appVisibility class="image-logo-pktcambies bounceInLeft">

    <div id="logopktmovil">
      <img src="assets/img/beneficios/PAKTCAMBIES/LOGO_PAKT_CAMBIESredim.png" appVisibility
        class="image-logo-pktcambies-movil pulseInfinite">
    </div>

    <div class="rightside_banner">
      <img class="right_background" src="assets/img/beneficios/PAKTCAMBIES/right_background.png">

      <img appVisibility class="car_slide lightSpeedIn" src="assets/img/beneficios/PAKTCAMBIES/carritopack.webp">
      <div class="container_reembolso">
        <img appVisibility class="destelloM pulseInfinite" src="assets/img/beneficios/PAKTCAMBIES/destelloMoneda.png">
        <img appVisibility class="coin1 tada" src="assets/img/beneficios/PAKTCAMBIES/coin1.webp">
        <img appVisibility class="coin2 bounceInLeft" src="assets/img/beneficios/PAKTCAMBIES/coin2.webp">
        <img appVisibility class="coin3 bounceInLeft" src="assets/img/beneficios/PAKTCAMBIES/coin3.webp">
        <img appVisibility class="coin4 bounceInLeft" src="assets/img/beneficios/PAKTCAMBIES/coin4.webp">
        <img appVisibility class="coin5 fadeInRight" src="assets/img/beneficios/PAKTCAMBIES/coin5.webp">
        <img appVisibility class="coin6 fadeInRight" src="assets/img/beneficios/PAKTCAMBIES/coin6.webp">
        <img appVisibility class="coin7 fadeInRight" src="assets/img/beneficios/PAKTCAMBIES/coin7.webp">
        <img appVisibility class="coinh1 animated flip" src="assets/img/beneficios/PAKTCAMBIES/coinh1.webp">
        <img appVisibility class="coinh2 animated flip" src="assets/img/beneficios/PAKTCAMBIES/coinh2.webp">
        <img appVisibility class="coinh3 animated flip" src="assets/img/beneficios/PAKTCAMBIES/coinh3.webp">
        <img appVisibility class="coinh4 animated flip" src="assets/img/beneficios/PAKTCAMBIES/coinh4.webp">
      </div>
      <img appVisibility class="trailer_slide shakeInfinite" src="assets/img/beneficios/PAKTCAMBIES/trailerPack.webp">
    </div>
<!-- 
    <div class="content-footer-elements">
      <div class="content1">
        <div class="box-white">
          <p appVisibility class="typewriter">Envíos Gratis</p>
        </div>
        <img src="assets/img/beneficios/PAKTCAMBIES/trailer_paktredim.png" appVisibility class="bounceInLeft">
      </div>

      <div class="content2">
        <div class="box-white">
          <p appVisibility class="typewriter">Servicio de recolección y domicilio sin costo</p>
        </div>
        <img src="assets/img/beneficios/PAKTCAMBIES/van_paktredim.png" appVisibility class="pulse">
      </div>

      <div class="content3">
        <div class="box-white">
          <p appVisibility class="typewriter">Reembolso en tu primera compra</p>
        </div>
        <img src="assets/img/beneficios/PAKTCAMBIES/REEMBOLSOredim.png" appVisibility class="tada">
      </div>

    </div> -->
    <button id="btnpaktmovil" routerLink="/PaKTCambies">¡Cámbiate aquí! <span class="material-symbols-outlined">
        chevron_right </span></button>
  </div>
  <!--   ---------------------   -->


  <!--   -----------   Seccion beneficios tufesapackhoras    -----------   -->
  <div class="content-beneficio" *ngIf="horasbeneficio">
    <img id="backgrndhorasdesktop" src="assets/img/beneficios/TUFESAPACK_HORAS/FONDO_3redu.webp" class="backimage-horas">
    <div class="cont_animationLogo">
      <img appVisibility class="clock_tfph bounceIn"  src="assets/img/beneficios/TUFESAPACK_HORAS/reloj_tfpH.webp">
      <img appVisibility class="fx_luces pulseInfinite"  src="assets/img/beneficios/TUFESAPACK_HORAS/fx_luces.webp">
      <img appVisibility class="car_flip lightSpeedIn"  src="assets/img/beneficios/TUFESAPACK_HORAS/car_tfp.webp">
      <img appVisibility class="box_flip bounceInRight"  src="assets/img/beneficios/TUFESAPACK_HORAS/cajaysobre.webp">
    </div>
    <!-- <img class="gif-tfph" src="assets/img/beneficios/TUFESAPACK_HORAS/tfpHoras.gif"> -->

    <div id="imagetablet" class="content-tablet-image">
      <img src="assets/img/beneficios/TUFESAPACK_HORAS/tfpHoras.gif" class="backimage-horas">
    </div>

    <div class="content-text forHoras">
      <p appVisibility class="fadeInLeft">¡ENVÍA Y RECIBE EL MISMO DÍA!</p>
      <p appVisibility class="bounceInLeft">Tus envíos llegan hoy.</p>
      <button id="btnhorasdesktop" routerLink="/tufesapack-horas">¡Conoce más!<span class="material-symbols-outlined">
          chevron_right </span></button>
    </div>

    <div id="contenthorasmovil" appVisibility class="content-image-movil">
      <img src="assets/img/beneficios/TUFESAPACK_HORAS/tfpHoras.gif" class="image-horas-movil">
    </div>

    <div class="content-hashtag">
      <label>#EnvíosRápidosySeguros</label>
    </div>

    <button id="btnhorasmovil" routerLink="/tufesapack-horas">¡Conoce más!<span class="material-symbols-outlined">
        chevron_right </span></button>
  </div>
  <!--   ---------------------   -->


  <!--   -----------   Seccion beneficios pakganes    -----------   -->
  <div class="content-beneficio" *ngIf="pakganesbeneficio">
    <img class="img_box" src="assets/img/beneficios/PAKGANES/caja_pkg.png">
    <img appVisibility class="logoPakganes zoomIn" src="assets/img/beneficios/PAKGANES/logo_pkganes.png">
    <img src="assets/img/beneficios/PAKGANES/fondo_pakganes.png" class="backimage-pkganes">
    <div class="content-text seccpkganes">
      <p appVisibility class="p_secc3 fadeInLeft">REFIERE NUESTROS SERVICIOS Y OBTÉN:</p>
      <p appVisibility class="p_sub bounceInLeft">• Envíos Gratis.</p>
      <p appVisibility class="p_sub bounceInLeft">• Reembolso en tu primera compra.</p>
      <button id="btnpaktdesktop" style="margin-top: 35px;" routerLink="/PaKGanes" class="width_pkganes">Ver más <span
          class="material-symbols-outlined "> chevron_right </span></button>
    </div>

    <div class="txtPakganes">
      <!-- <p>INVITA Y OBTÉN</p>
    <p>BENEFICIOS</p> -->
      <img src="assets/img/beneficios/PAKGANES/slogan.png">
      <img class="pulseInfinite" src="assets/img/beneficios/PAKGANES/star.png" appVisibility >
    </div>

    <img appVisibility class="porc1 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-01.png">
    <img appVisibility class="porc2 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-02.png">
    <img appVisibility class="porc3 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-03.png">
    <img appVisibility class="porc4 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-04.png">
    <img appVisibility class="porc5 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-05.png">
    <img appVisibility class="porc6 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-06.png">
    <img appVisibility class="porc7 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-07.png">
    <img appVisibility class="porc8 animated flip" src="assets/img/beneficios/PAKGANES/porcentaje-08.png">

    <button id="btnpaktmovil" routerLink="/PaKGanes" >Ver más <span class="material-symbols-outlined">
      chevron_right </span></button>
  </div>
  <!--   ---------------------   -->

  <div class="content-items">
    <div appVisibility class="card-item slideInRight" (click)="showBeneficio('paktcambies')" *ngIf="cardpaktcambies">
      <div class="card-item-icon">
        <img src="assets/img/beneficios/PAKTCAMBIES/LogoPaKTCambiesredim.png" alt="">
      </div>
      <div class="card-item-text">
        <label>PAKTCAMBIES</label>
        <p>Mejoramos tus envíos</p>
        <label (click)="showBeneficio('paktcambies')" appVisibility class="pulseInfinite">Ver Más</label>
      </div>
    </div>
    <div appVisibility class="card-item slideInRight" (click)="showBeneficio('horas')" *ngIf="cardhoras">
      <div class="card-item-icon">
        <img src="assets/img/beneficios/TUFESAPACK_HORAS/LOGOTPHORASredim.png" alt="">
      </div>
      <div class="card-item-text">
        <label>TUFESAPACK HORAS</label>
        <p>Envía y recibe el mismo día</p>
        <label (click)="showBeneficio('horas')" appVisibility class="pulseInfinite">Ver Más</label>
      </div>
    </div>

    <div appVisibility class="card-item slideInRight" (click)="showBeneficio('pakganes')" *ngIf="cardpakganes">
      <div class="card-item-icon">
        <img src="assets/img/beneficios/PAKGANES/popup_pakganes.png" alt="">
      </div>
      <div class="card-item-text">
        <label>PAKGANES</label>
        <p>¡Invita y obtén beneficios!</p>
        <label (click)="showBeneficio('pakganes')" appVisibility class="pulseInfinite">Ver Más</label>
      </div>
    </div>
    <!-- <div class="card-item">
   <div class="card-item-icon">
     <img src="assets/img/beneficios/LogoPaKTCambies.png" alt="">
   </div>
   <div class="card-item-text">
     <label>PAKTCAMBIES</label>
     <label>Ver Más</label>
   </div>
 </div> -->
  </div>

  <div id="toggle-movil" class="toggle-items-movil">

    <div class="toggle-item" (click)="showBeneficio('paktcambies')">
      <div
        [ngClass]="{'toggle-item-text-active': activeToggle === 'paktcambies', 'toggle-item-text': activeToggle !== 'paktcambies'}">
        <label>PAKTCAMBIES</label>
      </div>
    </div>
    <div class="toggle-item" (click)="showBeneficio('horas')">
      <div
        [ngClass]="{'toggle-item-text-active': activeToggle === 'horas', 'toggle-item-text': activeToggle !== 'horas'}">
        <label>TFP HORAS</label>
      </div>
    </div>

    <div class="toggle-item" (click)="showBeneficio('pakganes')">
      <div
        [ngClass]="{'toggle-item-text-active': activeToggle === 'pakganes', 'toggle-item-text': activeToggle !== 'pakganes'}">
        <label>PAKGANES</label>
      </div>
    </div>

  </div>


</div>