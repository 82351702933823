<div class="container_herramienta">
  <img src="assets/img/beneficios/LANDINGPAKTCAMBIES.png " class="img_toAnimate">
  <div class="alinearIzq">
      <h1 class="title-section">Servicio de portabilidad - PAKT CAMBIES</h1>
          <div class="descripcion_cont">
          <p>
            <b>PaKTCambies</b><br>
            Tus envíos más rápidos desde hoy.<br>
            ¡Es hora de un cambio! Optimiza tus envíos y accede a beneficios exclusivos diseñados 
            para impulsar tus operaciones con la calidad y rapidez de TufesaPack®️.
          </p>
          <p>
              <b>¿Qué es PaKTCambies, la portabilidad de TufesaPack®?</b><br>
              Un programa único de portabilidad creado para clientes con contrato en otras paqueterías, 
              diseñado para clientes que buscan rapidez, seguridad y tarifas competitivas en sus envíos.<br>
              Al cambiarte a TufesaPack®️, no solo mejoras tus envíos, también disfrutas de incentivos 
              que hacen más eficiente y rentable tu experiencia logística.
          </p>
          <p>
              <b>Beneficios</b><br>
              <label style="text-decoration: underline;">Prepago</label>
              <br>
              <li><strong>10 Envíos Gratis</strong> (válidos durante los primeros 30 días).</li>
              <li><strong>10% de Reembolso</strong> en tu primera compra.</li>
              <li><strong>Servicio de Recolección y Domicilio sin Costo</strong> por 30 días naturales en la sucursal de origen.</li>
          </p>
          <p>
              <b>¿Cómo funciona?</b><br>
          </p>
          <ol>
            <li><strong>Cambia tu contrato:</strong>  Si actualmente cuentas con contrato en otra paquetería, preséntanos tu historial de consumo de los últimos 3 meses.
              </li>
            <li><strong>Elige tu plan ideal:</strong>  Selecciona entre las modalidades, <strong>Prepago</strong>  o <strong>Crédito</strong>.
            </li>
            <li><strong>Activa tus beneficios:</strong>  Disfruta de envíos gratis, reembolsos y más desde el primer día con TufesaPack®️.
              </li>
          </ol>
          <p>Se asignará a un ejecutivo personalizado que te guiará durante tu proceso de cambio.</p>

          <p>
              <b>Restricciones</b><br>

              <li>Los beneficios aplican únicamente en modalidad de <strong>guías web</strong>.</li>
              <li>Los envíos gratuitos obtenidos están sujetos a los términos y condiciones expresadas en el contrato de adhesión.</li>
              <li>Contrato especial con plazo forzoso de 3 meses. (Penalización por incumplimiento).</li>
          </p>

          <p>
              <b>Disponibilidad</b><br>
              PaKTCambies está disponible en todas las sucursales, pero la capacidad de envío puede variar según la ubicación, 
              algunas de las siguientes sucursales con capacidad son:
              <br><br>
              <li>Guadalajara</li>
              <li>Hermosillo</li>
              <li>Ciudad Obregón</li>
              <li>Los Mochis</li>
              <li>Tijuana</li>
          </p>

          <p>
              <b>¿Por qué elegir TufesaPack®?</b><br>
              Con TufesaPack®️, obtienes más que envíos rápidos y seguros; garantizamos soluciones personalizadas, 
              incentivos únicos, una experiencia confiable que mejora tu logística y reduce tus costos combinando 
              innovación y servicio para ofrecerte soluciones que realmente se ajusten a tus necesidades.<br>

              ¡Haz el cambio hoy mismo! Activa nuestro programa de portabilidad <strong>PaKTCambies</strong> y da el
              primer paso hacia un servicio de paquetería más eficiente y lleno de beneficios, donde
              hacemos más fácil y rentable tus envíos.
          </p>

      </div>
  </div>
</div>

