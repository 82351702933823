<div class="promo-slider" id="">

  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      <!-- <li data-target="#carouselExampleIndicators" data-slide-to="5"></li> -->
      <!-- <li data-target="#carouselExampleIndicators" data-slide-to="6"></li> -->
      <!-- <li data-target="#carouselExampleIndicators" data-slide-to="7"></li> -->
    </ol>
    <div class="carousel-inner">
      <!-- <div class="carousel-item active" routerLink="/segundoAniversario"  id="seleccionable">
        <img src="assets/img/promociones/segundoAniversarioTfp_1920x754.webp" class="d-block w-100" alt="...">
      </div> -->
      <div class="carousel-item active" routerLink="/tufesapack-horas"  id="seleccionable">
        <img src="assets/img/promociones/tfp_horas.webp" class="d-block w-100" alt="...">
      </div>
      <!-- <div class="carousel-item" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPNVJM24'}" id="seleccionable">
        <img src="assets/img/promociones/bannerpromonavojoa.png" class="d-block w-100" alt="...">
      </div> -->
      <!-- <div class="carousel-item" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPSSMZ24'}" id="seleccionable">
        <img src="assets/img/promociones/bannerpromosemanasanta.jpg" class="d-block w-100" alt="...">
      </div> -->
      <!-- <div class="carousel-item " routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPHMOMZ24'}" id="seleccionable">
        <img src="assets/img/promociones/promohmobanner.jpg" class="d-block w-100" alt="...">
      </div> -->
      <div class="carousel-item">
        <img src="assets/img/promociones/banner-tp1.webp" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/img/promociones/banner-tp2.webp" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item" routerLink="/sucursales" id="seleccionable">
        <img src="assets/img/promociones/banner-tp3.webp" class="d-block w-100" alt="...">
      </div>
      <!-- <div class="carousel-item" routerLink="/tupromo"  [queryParams]="{ code: 'PROMOTPMZNF24'}" id="seleccionable">
        <img src="assets/img/promociones/Banner-web-Carnaval.png" class="d-block w-100" alt="...">
      </div> -->
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

</div>
