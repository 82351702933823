import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CotizacionService } from "../../servicios/cotizacion.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Package } from "src/app/entidades/package";
import { ThrowStmt } from "@angular/compiler";
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ɵConsole,
} from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { MensajePopup } from "../../entidades/MensajePopup";
import { MensajeService } from "../../servicios/mensaje.service";
import { HttpErrorResponse } from "@angular/common/http";
import  Swal  from 'sweetalert2';

@Component({
  selector: "app-cotizacion",
  templateUrl: "./cotizacion.component.html",
  styleUrls: ["./cotizacion.component.css"],
})
export class CotizacionComponent implements OnInit {
  cotizaForm: FormGroup;
  cotizaAvanzadoForm: FormGroup;
  serviciosForm: FormGroup;
  origen: string;
  destino: string;
  cotizacion: any;
  rspregcot: any;
  respuestasecreg: any;
  costoPq: any = "-";
  costoSob: any = "-";
  tipoPaq: string = "";
  arrPack = [];
  ciudades: any;
  submitted = false;
  submitted2 = false;
  submittedAvz = false;

  ban: boolean = false;
  modalRef: BsModalRef;
  detorigen: string;
  detdestino: string;
  directorio: any;
  title = "My first AGM project";
  lat = 27.481338;
  lng = -109.945187;

  bancotizacion = true;
  listatipo: string[] = ["Seleccione", "Sobre", "Caja"];
  banTipoPaq = false;
  arr2 = [];
  showMe: boolean = true;
  nombreSeccionRegistro:string;
  apellidoSeccionRegistro:string;
  correoSeccionRegistro:string;

  isClassActive: boolean = true;
  inputnombre:string="";
  inputapellido:string="";
  inputtelefono:string="";
  inputcorreo:string="";

  tfdv:string="";
  correoFormCotizar:string;
  mostrarInputCorreo:boolean = false;

  constructor(
    private router: Router,
    private cotizacionService: CotizacionService,
    private modalService: BsModalService,
    private mensajeService: MensajeService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.tfdv =""
    if(localStorage.getItem("tfdv")){
      this.tfdv = localStorage.getItem("tfdv")
    }

    this.cotizacionService.origen.subscribe((d) => (this.origen = d));
    this.cotizacionService.destino.subscribe((d) => (this.destino = d));

    if (this.origen && this.destino) {
      this.cotizacionService
        .getCotizacion(this.origen, this.destino, "1",this.tfdv,"")
        .subscribe((datos) => {
          this.cotizacion = datos;
          if (this.cotizacion.numrsp > 0) {
            this.costoPq = "-";
            this.costoSob = "-";
            for (let i = 0; i < this.cotizacion.costos.length; i++) {
              this.cotizacion.costos[i].Tipo === "SB"
                ? (this.costoSob = this.cotizacion.costos[i].CostoFinal)
                : (this.costoPq = this.cotizacion.costos[i].CostoFinal);
            }
          }
        });
    }

    this.cotizacionService.getCiudades().subscribe((datos) => {
      this.ciudades = datos;
    });

    this.createForm();
    this.createFormAvanzado();
    this.createFormServicios();

    if (this.tfdv != ""){
      this.cotizacionService
      .getregcorr(this.tfdv)
      .subscribe((datos) => {

        console.log(datos);

        if (datos === "1") {
            this.mostrarInputCorreo = true;
        }else{
            this.mostrarInputCorreo = false;
        }
      });
    }
  }

  createForm() {
    this.cotizaForm = new FormGroup({
      origen: new FormControl(this.origen ? this.origen : "0", [
        Validators.required,
      ]),
      destino: new FormControl(this.destino ? this.destino : "0", [
        Validators.required,
      ]),
      correoFormCotizar: new FormControl('', [
        // Validators.required,
           Validators.email,
      ]),
      tipo: new FormControl(this.listatipo ? this.listatipo[0] : "0", [
        Validators.required,
      ]),
      peso: new FormControl("1", [Validators.required]),
    });


  }


  createFormAvanzado() {
    this.cotizaAvanzadoForm = new FormGroup({
      cantidad: new FormControl("", [Validators.required]),
      largo: new FormControl(
        "",
        this.tipoPaq === "Caja" ? [Validators.required] : []
      ),
      alto: new FormControl(
        "",
        this.tipoPaq === "Caja" ? [Validators.required] : []
      ),
      ancho: new FormControl(
        "",
        this.tipoPaq === "Caja" ? [Validators.required] : []
      ),
      peso: new FormControl("", [Validators.required]),
    });
  }
  createFormServicios() {
    this.serviciosForm = new FormGroup({
      porcob: new FormControl(false, [Validators.required]),
      seguro: new FormControl(false, [Validators.required]),
      monto: new FormControl("", []),
      domic: new FormControl(false, [Validators.required]),
      dirDomic: new FormControl("", []),
      recol: new FormControl(false, [Validators.required]),
      embalaje: new FormControl(false, [Validators.required]),
      dirReco: new FormControl("", []),
      SelEmbalaje: new FormControl("", []),
      recibido: new FormControl(false, [Validators.required]),
    });
  }


  get Avanzado() {
    return this.cotizaAvanzadoForm.controls;
  }

  getCotizacion(tipo) {
    // this.cotizaForm.markAllAsTouched();
    this.submitted = true;
    if (this.cotizaForm.valid) {
      if (
        this.cotizaForm.value.origen !== "0" &&
        this.cotizaForm.value.destino !== "0"
      ) {
        if (tipo === "bsc") {
          if (this.cotizaForm.valid) {
            this.cotizacionService
              .getCotizacion(
                this.cotizaForm.value.origen,
                this.cotizaForm.value.destino,
                this.cotizaForm.value.peso,
                this.tfdv,
                this.cotizaForm.value.correoFormCotizar
              )
              .subscribe((datos) => {
                this.cotizacion = datos;
                if (this.cotizacion.numrsp > 0) {
                  this.costoPq = "-";
                  this.costoSob = "-";
                  for (let i = 0; i < this.cotizacion.costos.length; i++) {
                    this.cotizacion.costos[i].Tipo === "SB"
                      ? (this.costoSob = this.cotizacion.costos[i].CostoFinal)
                      : (this.costoPq = this.cotizacion.costos[i].CostoFinal);
                  }
                }
              });
          }
        } else {
          this.submitted2 = true;
          if (this.arrPack.length > 0) {
            let cantidad = "",
              largo = "",
              alto = "",
              ancho = "",
              peso = "";

            for (let i = 0; i < this.arrPack.length; i++) {
              if (this.tipoPaq === "Caja") {
                if (i + 1 === this.arrPack.length) {
                  cantidad += this.arrPack[i].cantidad;
                  largo += this.arrPack[i].largo;
                  alto += this.arrPack[i].alto;
                  ancho += this.arrPack[i].ancho;
                  peso += this.arrPack[i].peso;
                } else {
                  cantidad += this.arrPack[i].cantidad + ",";
                  largo += this.arrPack[i].largo + ",";
                  alto += this.arrPack[i].alto + ",";
                  ancho += this.arrPack[i].ancho + ",";
                  peso += this.arrPack[i].peso + ",";
                }
              } else {
                if (i + 1 === this.arrPack.length) {
                  cantidad += this.arrPack[i].cantidad;
                  peso += this.arrPack[i].peso;
                  largo += 10;
                  alto += 10;
                  ancho += 10;
                } else {
                  cantidad += this.arrPack[i].cantidad + ",";
                  peso += this.arrPack[i].peso + ",";
                  largo += 10 + ",";
                  alto += 10 + ",";
                  ancho += 10 + ",";
                }
              }
            }

            if (this.serviciosForm.value.monto == "") {
              this.serviciosForm.value.monto = 0;
            }
            this.cotizacionService.getCotizacionAvanzada(
              this.cotizaForm.value.origen,
              this.cotizaForm.value.destino,
              this.tipoPaq,
              cantidad,
              largo,
              alto,
              ancho,
              peso,
              this.serviciosForm.value.porcob,
              this.serviciosForm.value.seguro,
              this.serviciosForm.value.monto,
              this.serviciosForm.value.domic,
              this.serviciosForm.value.dirDomic,
              this.serviciosForm.value.recol,
              this.serviciosForm.value.dirReco,
              this.serviciosForm.value.embalaje,
              this.serviciosForm.value.recibido,
              this.serviciosForm.value.SelEmbalaje,
              this.tfdv,
              this.cotizaForm.value.correoFormCotizar
            );
            this.router.navigate(["/cotizacion/resumen"]);
          }
        }
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  configAvanzada(tipo) {
    if (this.tipoPaq === tipo) {
      return;
    } else {
      this.arrPack = [];
      this.tipoPaq = tipo;
      let elem = document.getElementById("confAvanzada");
      elem.style.opacity = "1";
      elem.style.transition = "opacity 1s ease-out";
      elem.style.display = "block";
      this.createFormAvanzado();
      this.createFormServicios();
    }
  }

  addPackages() {
    this.submittedAvz = true;
    if (this.cotizaAvanzadoForm.valid) {
      let pack = new Package();
      pack.cantidad = this.cotizaAvanzadoForm.value.cantidad;
      pack.largo = this.cotizaAvanzadoForm.value.largo;
      pack.alto = this.cotizaAvanzadoForm.value.alto;
      pack.ancho = this.cotizaAvanzadoForm.value.ancho;
      pack.peso = this.cotizaAvanzadoForm.value.peso;
      this.arrPack.push(pack);
      this.createFormAvanzado();
      this.submittedAvz = false;
    }
  }

  showInput(e) {
    switch (e) {
      case "monto": {
        let elem = document.getElementById("monto");
        if (this.serviciosForm.value.seguro === false) {
          elem.style.opacity = "1";
          elem.style.transition = "opacity 1s ease-out";
          elem.style.display = "block";
        } else {
          elem.style.display = "none";
          elem.style.opacity = "0";
        }
        break;
      }
      case "domic": {
        let elem = document.getElementById("domicilio");
        if (this.serviciosForm.value.domic === false) {
          elem.style.opacity = "1";
          elem.style.transition = "opacity 1s ease-out";
          elem.style.display = "block";
        } else {
          elem.style.display = "none";
          elem.style.opacity = "0";
        }
        break;
      }
      case "recoleccion": {
        let elem = document.getElementById("recoleccion");
        if (this.serviciosForm.value.recol === false) {
          elem.style.opacity = "1";
          elem.style.transition = "opacity 1s ease-out";
          elem.style.display = "block";
        } else {
          elem.style.display = "none";
          elem.style.opacity = "0";
        }
        break;
      }
      case "embalaje": {
        let elem = document.getElementById("embalaje");
        if (this.serviciosForm.value.embalaje === false) {
          elem.style.opacity = "1";
          elem.style.transition = "opacity 1s ease-out";
          elem.style.display = "block";
        } else {
          elem.style.display = "none";
          elem.style.opacity = "0";
        }
        break;
      }
    }
  }

  abrirDetalleGps(punto) {
    if (punto == "O") {
      if (this.cotizaForm.value.origen == 0) {
        this.mensajeService.subject$.next(
          new MensajePopup(
            "Es necesario seleccionar un origen para continuar",
            false,
            false
          )
        );
      } else {
        this.ban = false;
        this.directorio = "";
        this.obtenerdirectorio(this.cotizaForm.value.origen, "PQT");
      }
    } else {
      if (
        this.cotizaForm.value.origen == 0 ||
        this.cotizaForm.value.destino == 0
      ) {
        this.mensajeService.subject$.next(
          new MensajePopup(
            "Es necesario seleccionar un origen y destino para continuar",
            false,
            false
          )
        );
      } else {
        this.ban = true;
        this.directorio = "";
        this.obtenerdirectorio(this.cotizaForm.value.destino, "PQT");
      }
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

  ChangedOrigen(no) {
    this.detorigen = no.sucursal;
    this.cotizacionService.setOrigen(no.cp);
  }

  ChangedDestino(no) {
    this.detdestino = no.sucursal;
    this.cotizacionService.setDestino(no.cp);
  }

  Changedtipo(no) {}

  obtenerdirectorio(cp, tipo) {
    this.cotizacionService.getdirectorio(cp, tipo).subscribe((datos) => {
      this.directorio = datos;

      if (this.directorio == "") {
        this.mensajeService.subject$.next(
          new MensajePopup(
            "Por el momento no contamos con sucursales en tu ciudad.",
            false,
            false
          )
        );
      } else {
        const btn = document.getElementById("btn-modal") as HTMLElement;
        btn.click();
      }
    });
  }

  abrirDetalle(id, datos) {
    if (document.getElementById(id).style.display == "none") {
      document.getElementById(id).style.display = "block";
    } else {
      document.getElementById(id).style.display = "none";
    }
  }

  cambiarcheck(val) {}

  cotizacionPor(opcion: number) {
    if (opcion === 1) {
      this.bancotizacion = true;
      this.arrPack = [];
      document.getElementById("txt_cotRapida").style.color = "#F05A28";
      document.getElementById("txt_cotAvanzada").style.color = "#6d6e70";
      // devolver imagen de origen al cambiar a cotización rápida
      // document.getElementById("img_toChange").style.visibility = "visible";
      // document.getElementById("img_toChange").style.opacity = "1";
      // document.getElementById("showSobre").style.opacity = "0";
      // document.getElementById("showCaja").style.opacity = "0";
      // document.getElementById("img_toChange").style.transition = "opacity 1s";
      // document.getElementById("showSobre").style.visibility = "hidden";
      // document.getElementById("showCaja").style.visibility = "hidden";
    } else if (opcion === 2) {
      this.bancotizacion = false;
      this.arrPack = [];
      this.tipoPaq = "";
      this.banTipoPaq = false;
      document.getElementById("txt_cotRapida").style.color = "#6d6e70";
      document.getElementById("txt_cotAvanzada").style.color = "#F05A28";
    }
  }
  toggleCambiar() {
    if ((this.showMe = !this.showMe)) {
      this.cotizacionPor(1);
    } else {
      this.showMe === this.showMe;
      this.cotizacionPor(2);
    }
  }

  onChange(deviceValue) {
    this.arrPack = [];

    this.tipoPaq = deviceValue;
    if (this.tipoPaq === "Caja") {
      // mostrar la imagen de caja - ocultando imagen de sobre e imagen inicial.
      // document.getElementById("showCaja").style.visibility = "visible";
      // document.getElementById("showCaja").style.opacity = "1";
      // document.getElementById("img_toChange").style.opacity = "0";
      // document.getElementById("showSobre").style.opacity = "0";
      // document.getElementById("showCaja").style.transition = "opacity 1s";
      // document.getElementById("img_toChange").style.visibility = "hidden";
      // document.getElementById("showSobre").style.visibility = "hidden";
    } else if (this.tipoPaq === "Sobre") {
      // mostrar la imagen de sobre - ocultando imagen de caja e imagen inicial.
      // document.getElementById("showCaja").style.visibility = "hidden";
      // document.getElementById("showCaja").style.opacity = "0";
      // document.getElementById("img_toChange").style.opacity = "0";
      // document.getElementById("img_toChange").style.visibility = "hidden";
      // document.getElementById("showSobre").style.visibility = "visible";
      // document.getElementById("showSobre").style.opacity = "1";
      // document.getElementById("showSobre").style.transition = "opacity 1s";
    } else {
      // mostrar la imagen inicial y ocultar las anteriores.
      // document.getElementById("img_toChange").style.visibility = "visible";
      // document.getElementById("img_toChange").style.opacity = "1";
      // document.getElementById("showSobre").style.opacity = "0";
      // document.getElementById("showCaja").style.opacity = "0";
      // document.getElementById("img_toChange").style.transition = "opacity 1s";
      // document.getElementById("showSobre").style.visibility = "hidden";
      // document.getElementById("showCaja").style.visibility = "hidden";
    }

    if (this.tipoPaq == "Caja" || this.tipoPaq == "Sobre") {
      this.createFormAvanzado();
      this.submittedAvz = false;
      this.banTipoPaq = true;
    } else {
      this.banTipoPaq = false;
    }
  }

  quitarTipo(id) {
    var i2 = this.arrPack.indexOf(id);

    if (id !== -1) {
      this.arrPack.splice(id, 1);
      this.arr2 = this.arrPack.slice();
    }
  }

  getPaquete(val) {
    let num;
    if (val == "PQ") {
      num = 1;
    } else if (val == "SB") {
      num = 2;
    }
    return num;
  }

  mandarRecolecion() {
    var url;
    var tmpidcd;
    var tmpidedo;

    for (let i = 0; i < this.ciudades.length; i++) {
      if (this.ciudades[i].cp == this.cotizaForm.value.origen) {
        tmpidcd = this.ciudades[i].idcd;
        tmpidedo = this.ciudades[i].idedo;
        break;
      }
    }
    // url = ".../../paqueteria/recoleccion?cd=" + tmpidcd + "&edo=" + tmpidedo;
    // window.location.href = url;

    const queryParams = { cd: tmpidcd, edo: tmpidedo };
    this.router.navigate(['/paqueteria/recoleccion'],{ queryParams: queryParams });

  }

  openCloseInfo(){
    this.isClassActive = !this.isClassActive;

    //volver a dejar vacios los inputs
    this.inputnombre = "";
    this.inputapellido = "";
    this.inputcorreo = "";
    this.inputtelefono = "";
  }

  solicitarMasInfo(){
    if (this.inputnombre ===""){
      alert("Capture su nombre") ;
      return;
    }

    if (this.inputapellido ===""){
      alert("Capture su apellido") ;
      return;
    }

    if (this.inputcorreo ===""){
      alert("Capture su correo") ;
      return;
    }

    if (this.inputtelefono ===""){
      alert("Capture su teléfono") ;
      return;
    }
    //continuar con el proceso de envío
    //regsolcotizacion

    this.cotizacionService
    .regsolcotizacion( "ALT","0",this.inputapellido ,this.inputnombre,this.inputcorreo , this.inputtelefono,this.cotizacion.numrsp, "GEN" )
    .subscribe((datos) => {

      this.rspregcot =datos;

      if (this.rspregcot.id > 0) {
        //ocultar formulario
        this.isClassActive = true;

        //volver a dejar vacios los inputs
        this.inputnombre = "";
        this.inputapellido = "";
        this.inputcorreo = "";
        this.inputtelefono = "";
      }
    });
  }

  validarCorreoElectronico(correo: string): any {
    const patronCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const esValido = patronCorreo.test(correo);
    return esValido;
  }

  validarCampoVacio(valor: string): boolean {
    if (valor == undefined || valor == null) {
      return true;
    }
    return valor.trim() === '';
  }

  registrarSectionForm(nombre,apellido, correo) {

    console.log(nombre,apellido,correo);

    if (this.validarCampoVacio(nombre) || this.validarCampoVacio(apellido) || this.validarCampoVacio(correo)) {
        const nombreInput = document.getElementById('nombreinputsecregistro') as HTMLInputElement;
        nombreInput.focus();
        Swal.fire({
          title: "ERROR",
          text: "Por favor, complete todos los campos.",
          // icon: "question"
        });

        return;
      }


    const esCorreoValido = this.validarCorreoElectronico(correo);

    if (esCorreoValido) {
        // correo válido
    } else {
      // correo inválido
      Swal.fire({
        title: "ERROR",
        text: "Por favor, ingrese un correo válido.",
        icon: "info"
      });
      return;
    }

    this.cotizacionService.regsolcotizacion("ALT", "0",this.apellidoSeccionRegistro, this.nombreSeccionRegistro,this.correoSeccionRegistro, "", "", "GEN")
    .subscribe(
      (datos) => {
         console.log("Esta es la respuesta - "+ JSON.stringify(datos));
         this.respuestasecreg = datos

        if (this.respuestasecreg.id > 0) {
            this.nombreSeccionRegistro= "";
            this.apellidoSeccionRegistro= "";
            this.correoSeccionRegistro = "";

            Swal.fire({
              title: "¡Registrado con éxito!",
              text: "Ahora puedes aprovechar nuestras tarifas especiales. ",
              icon: "success"
            });
        }else{
          Swal.fire({
            title: "¡ERROR!",
            text: this.respuestasecreg.msg,
            icon: "error"
          });
        }
      },
      (error) => {
        console.error("Error en la solicitud de registro de cotización:", error);
        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error"
        });

      });

  }


}

