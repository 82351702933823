<div class="container_herramienta">
    <img appVisibility src="assets/img/beneficios/ANIVERSARIO/img_landingAniversario.webp" class="img_toAnimate pulseInfinite">
    <div class="alinearIzq">
        <h1 class="txt-section">Aniversario TufesaPack</h1>
            <div class="descripcion_cont">
            <p>
                <b class="title">TufesaPack celebra su aniversario con grandes promociones</b><br><br>
                Del 01 al 31 de marzo de 2025, queremos agradecer tu preferencia con descuentos especiales en envíos, brindando una experiencia más accesible y eficiente.
            </p>
           
            <p>
                <b class="title">Promoción de Aniversario Prepago</b><br><br>
                Si actualmente utilizas otro proveedor de paquetería, <b>esta es tu oportunidad para probar TUFESAPACK con beneficios exclusivos:</b>
                <br>
                <ul>
                <li>Tarifas igualadas con tu proveedor actual durante el primer mes.</li>
                <li>Bonificación del 20% en el tercer mes de consumo (calculado con el promedio de los dos primeros meses, con vigencia de hasta 3 meses y peso límite de 20 kg).</li>
                <li>Servicio a domicilio incluido, sujeto a disponibilidad por sucursal.</li>
                <li>Aplicación de <b>TUFESAPACK HORAS</b> para envíos con entrega el mismo día (según cobertura).</li>
                </ul>
          
            <p>
                <b class="title">¿Cómo participar?</b>
                <ol>
                    <li value="1">Acude a tu sucursal TUFESAPACK más cercana.</li>
                    <li>Presenta la propuesta de tarifas de tu proveedor actual.</li>
                    <li>Te igualamos la tarifa y comienzas a disfrutar de los beneficios.</li>
                    <li>Recibirás una bonificación del 20% en el tercer mes, basada en tu consumo promedio.</li>
                    <li>Si tu proveedor ofrece servicio a domicilio, lo incluiremos en tu plan (sujeto a disponibilidad).</li>
                </ol>
                
            <p>
                <b class="title">Restricciones y condiciones</b>
                <ul>
                <li>Tarifa igualada solo aplica en el primer mes.</li>
                <li>Bonificación del 20% válida en el tercer mes, basada en consumo promedio.</li>
                <li>Servicio a domicilio sujeto a disponibilidad por sucursal.</li>
                <li>No acumulable con otras promociones.</li>
            </ul>

            <p>
                <b class="title">Promoción exclusiva en mostrador</b>
                <ul>
                <li>Vigencia: del 13 al 17 de marzo de 2025.</li>
                <li>50% de descuento en envíos de sobres y paquetes de hasta 20 kg.</li>
                <li> 50% de descuento en envíos a domicilio.</li>
                </ul>

            <p>
                <b class="title">¿Cómo hacer válida la promoción?</b>
                <ol>
                    <li value="1">Acude a tu sucursal TUFESAPACK más cercana.</li>
                    <li>Presenta la propuesta de tarifas de tu proveedor actual.</li>
                    <li>Te igualamos la tarifa y comienzas a disfrutar de los beneficios.</li>
                    <li>Recibirás una bonificación del 20% en el tercer mes, basada en tu consumo promedio.</li>
                    <li>Si tu proveedor ofrece servicio a domicilio, lo incluiremos en tu plan (sujeto a disponibilidad).</li>
                </ol>
            

            <p>
                <b class="title">Términos y condiciones</b>
                <ul>
                    <li>Promoción exclusiva en mostrador, no aplicable en compras en línea.</li>
                    <li>Válida solo del 13 al 17 de marzo de 2025.</li>
                    <li>No acumulable con otras promociones o descuentos vigentes.</li>
                    </ul>
           
            <br>
            <p class="end_txt">
                <b>Consulta términos y condiciones en sucursal.</b><br>
                <span>Cotiza y consulta más detalles en <a class="link_stl" target="_blank" href="https://www.tufesapack.com/">www.tufesapack.com</a></span>
            </p>
        </div>
    </div>
</div>

